const SearchStatic = ({ staticData, handleSuggestionClick, noresults }) => {
    const sections = staticData.static.sections

    // // console.log sections', sections)

    return (
        <div className={`search-static-outer ${noresults ? 'py-4' : 'p-6'}`}>
            <div className="search-static-inner flex flex-wrap gap-4 xl:gap-12 xl:flex-nowrap">
                {sections.map((s, index) => {
                    if (s.title === 'Top Categories') {
                        return (
                            <div
                                className={`search-static-section w-full  static-section-${
                                    s.slug
                                } ${noresults ? 'w-full' : 'xl:w-3/4'}`}
                                key={index}
                            >
                                <div className="search-static-title text-base xl:text-xl mb-2">
                                    {s.title}
                                </div>
                                <div className="search-static-content mt-1 mb-4">
                                    <ul className="categories-list nostyle grid grid-cols-3 2xl:grid-cols-4 gap-x-4 gap-y-8">
                                        {s.categories.map((c, i) => {
                                            if (s.categories.length - 1 === i) {
                                                return (
                                                    <li
                                                        className="category-item lg:hidden"
                                                        key={i}
                                                    >
                                                        <div className="category-item-image hover:opacity-80">
                                                            <a href={c.url}>
                                                                <img
                                                                    src={c.imageSrc}
                                                                    alt={c.imageAlt}
                                                                />
                                                            </a>
                                                        </div>
                                                        <div className="category-item-title mt-2">
                                                            <a className="" href={c.url}>
                                                                {c.title}
                                                            </a>
                                                        </div>
                                                    </li>
                                                )
                                            } else {
                                                return (
                                                    <li
                                                        className="category-item"
                                                        key={i}
                                                    >
                                                        <div className="category-item-image hover:opacity-80">
                                                            <a href={c.url}>
                                                                <img
                                                                    src={c.imageSrc}
                                                                    alt={c.imageAlt}
                                                                />
                                                            </a>
                                                        </div>
                                                        <div className="category-item-title mt-2">
                                                            <a className="" href={c.url}>
                                                                {c.title}
                                                            </a>
                                                        </div>
                                                    </li>
                                                )
                                            }

                                        })}
                                    </ul>
                                </div>
                            </div>
                        )
                    }
                    return null
                })}
                <div
                    className={`search-static-stack w-full xl:w-1/4 xl:min-w-[320px] ${
                        noresults ? 'hidden' : ''
                    }`}
                >
                    {sections.map((s, index) => {
                        if (s.title === 'Top Searches') {
                            return (
                                <div
                                    className={`search-static-section static-section-${s.slug}`}
                                    key={index}
                                >
                                    <div className="search-static-title text-base xl:text-lg mb-2">
                                        {s.title}
                                    </div>
                                    <div className="search-static-content mt-1 mb-4">
                                        <ul className="searches-list nostyle flex flex-wrap gap-2 px-2 xl:flex xl:flex-col xl:flex-nowrap">
                                            {s.searches.map((search, i) => {
                                                return (
                                                    <li
                                                        className="search-item text-sm w-[100px] lg:w-[120px] font-normal text-flint hover:text-ash hover:underline cursor-pointer"
                                                        key={i}
                                                        onClick={() =>
                                                            handleSuggestionClick(
                                                                search.title
                                                            )
                                                        }
                                                    >
                                                        {search.title}
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            )
                        }
                        if (s.title === 'Top Classes + Events') {
                            return (
                                <div
                                    className={`search-static-section static-section-${s.slug} hidden xl:block`}
                                    key={index}
                                >
                                    <div className="search-static-title text-base xl:text-lg mb-2 flex items-center">
                                        {s.title}{' '}
                                        <a
                                            href="/pages/all-classes-events"
                                            className="view-all text-xs xl:text-base ml-auto font-normal text-flint hover:text-ash hover:underline hover:cursor-pointer"
                                        >
                                            View All »
                                        </a>
                                    </div>
                                    <div className="search-static-content mt-1 mb-4">
                                        <ul className="classes-list nostyle">
                                            {s.classes.map((c, i) => {
                                                return (
                                                    <li
                                                        className="class-item text-sm font-normal my-2"
                                                        key={i}
                                                    >
                                                        <a className="text-flint hover:text-ash hover:underline hover:cursor-pointer" href={c.url}>
                                                            {c.title}
                                                        </a>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            )
                        }
                        return null
                    })}
                </div>
            </div>
        </div>
    )
}

export default SearchStatic
